import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import AOS from 'aos';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {

    AOS.init({
        duration: 1000,
        easing: 'ease',
        once: true,
    });

    jQuery(document).foundation();

    const nav = document.querySelector('.main-menu');
    const menuBtn = document.querySelector('.menu-btn');
    const lines = document.querySelectorAll('.menu-btn .line');


    // Toggle navigation menu
    menuBtn.addEventListener('click', () => {
        nav.classList.toggle('open');
        lines.forEach((line, index) => {
            line.classList.toggle('line-cross');
            if (index === 1) line.classList.toggle('line-fade-out');
        });
    });

    const ul = document.querySelectorAll("nav#site-navigation ul li ul.children");
    const openSearchBtn = document.querySelector('.open-search-from');
    const deleteSearchBtn = document.querySelector('#deleteSearch');

    // Check if an element is scrolled into view
    function isScrolledIntoView(elem, delay = 50) {
        const docViewTop = $(window).scrollTop();
        const docViewBottom = docViewTop + $(window).height();
        const elemTop = $(elem).offset().top;
        const elemBottom = elemTop + $(elem).outerHeight();

        // Return a Promise to handle the delay
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(elemTop <= docViewBottom && elemBottom >= docViewTop);
            }, delay);
        });
    }

    // Initialize fade-in animation once after page load
    async function initFadeInAnimation() {
        const elements = $(".fade-in-letters");
        for (let i = 0; i < elements.length; i++) {
            const elem = elements[i];
            const isInView = await isScrolledIntoView(elem, 50);
            if (isInView && !$(elem).hasClass("visible")) {
                $(elem).addClass("visible");
            }
        }
    }

    // Check sections and trigger animations
    async function checkSectionsInView() {
        const sections = $("section");
        const footer = $("footer");

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const isInView = await isScrolledIntoView(section, 50);
            if (isInView && !$(section).hasClass("animated")) {
                $(section).addClass("in-view animated");
            }
        }

        const footerOffset = 720; // Trigger animation when within 720px of the page bottom
        const scrollPosition = window.scrollY + window.innerHeight; // Current bottom of the viewport
        const pageBottom = document.documentElement.scrollHeight; // Total height of the page

        if (pageBottom - scrollPosition <= footerOffset && !footer.hasClass("animated")) {
            setTimeout(() => {
                footer.addClass("in-view animated");
            }, 50);
        }
    }

    // Ensure promise is handled on scroll
    function handleScroll() {
        initFadeInAnimation().catch((error) => console.error("Error in initFadeInAnimation:", error));
        checkSectionsInView().catch((error) => console.error("Error in checkSectionsInView:", error));
    }

    // Check on scroll
    $(window).on("scroll", handleScroll);

    handleScroll();

    const checkScroll = () => {
        const header = document.querySelector('.site-header');
        window.scrollY > 0 ? header.classList.add('scrolled') : header.classList.remove('scrolled');
    };

    checkScroll();
    window.addEventListener('scroll', checkScroll);

    ul.forEach(ul => {
        ul.parentNode.classList.add('hasChildren');
        const expandLink = "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>";
        ul.parentNode.querySelector('a').insertAdjacentHTML('beforeend', expandLink);
    });

    document.addEventListener('click', e => {
        if (!nav.contains(e.target)) nav.classList.remove('open-nav');
    });

    const videoContainers = document.querySelectorAll('.video-container');

    videoContainers.forEach(videoContainer => {
        const playButton = videoContainer.querySelector('.play-button');
        const figure = videoContainer.querySelector('figure');
        const iframe = videoContainer.querySelector('iframe');

        if (playButton && iframe) {
            playButton.addEventListener('click', () => {
                playButton.style.display = 'none';
                figure.style.display = 'none';
                iframe.style.display = 'block';
            });
        }
    });

    jQuery('.footer-label').click(function ($) {
        jQuery(this).closest('.footer-menu').find('.footer-container ul').slideToggle();
        jQuery(this).closest('.footer-menu').find('i').toggleClass('open');
    });

    var gallerySlider = new Swiper(".gallery-slider", {
        direction: "horizontal",
        slidesPerView: 2,
        spaceBetween: 10,
        keyboard: {
            enabled: true,
        },
    });

    const postSlider = new Swiper('.post-slider', {
        modules: [Navigation],
        direction: "horizontal",
        slidesPerView: 1.2,
        spaceBetween: 32,
        loop: false,
        keyboard: {
            enabled: true,
        },
        navigation: {
            nextEl: ".swiper-post-next",
            prevEl: ".swiper-post-prev"
        },
        breakpoints: {
            '768': {
                slidesPerView: "auto",
                spaceBetween: 100,
            },
        },

    });

// Initialize Swipers
    document.querySelectorAll('.swiper-grid-container').forEach((container) => {
        new Swiper(container, {
            modules: [Navigation],
            spaceBetween: 10,
            slidesPerView: 1.2,
            direction: "horizontal",
            navigation: {
                nextEl: container.closest('.grid-container-block').querySelector('.swiper-product-next'),
                prevEl: container.closest('.grid-container-block').querySelector('.swiper-product-prev'),
            },
            breakpoints: {
                768: {
                    slidesPerView: 1.9,
                },
            },
        });
    });

    const reviewSlider = new Swiper(".swiper-review-container", {
        modules: [Navigation],
        spaceBetween: 10,
        slidesPerView: 1,
        direction: "horizontal",
        navigation: {
            nextEl: ".swiper-review-next",
            prevEl: ".swiper-review-prev"
        },
        breakpoints: {
            '768': {
                slidesPerView: 1.9,
            },
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
        },
        keyboard: {
            enabled: true,
        },
    });

    function isMobile() {
        return window.matchMedia("(max-width: 768px)").matches;
    }


    const textBlocks = document.querySelectorAll('.post-block');
    const images = document.querySelectorAll('.image-container');

    if (!isMobile()) {
        textBlocks.forEach((block) => {
            const imageIndex = block.dataset.image;
            ScrollTrigger.create({
                trigger: block,
                start: "top center",
                end: "bottom center",
                onEnter: () => changeImage(imageIndex),
                onLeaveBack: () => changeImage(imageIndex),
            });
        });
    }

    function changeImage(index) {
        images.forEach((container, i) => {
            container.classList.toggle('active', i + 1 === parseInt(index, 10));
        });
    }
});
